<template>
  <div>
    <div class="hotel-details-menu container py-3">
      <div class="py-3 border-bottom roboto-regular">
        <b-row>
          <b-col md="2">
            <div class="image-section ml-5">
              <b-img src="@/assets/icons/hotel-details/menu.svg" class="menu-img"></b-img>
            </div>
          </b-col>
          <b-col md="5">
            <div class="font-16 roboto-bold">Menus</div>
          </b-col>
        </b-row>
      </div>
      <div>
        <div class="pt-3">
          <b-row>
            <b-col md="3">
              <label class="font-14">Currency</label>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <v-select
                  v-model="detail.currency"
                  label="name"
                  :options="currencies"
                  return-object
                ></v-select>
                <div
                  v-if="
                    !$v.detail.currency.required &&
                      $v.detail.currency.$error &&
                      $v.detail.currency.$touch
                  "
                  class="error-text py-1"
                >
                  Currency is required
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- <div class="hotel-onfo-content mt-3">
        <b-card>
          
          <b-tabs justified>
            <b-tab class="p-3" v-for="tableData in menuList" v-bind:key="tableData">
              <template v-slot:title>
                <div class="font-14 text-uppercase" :class="tableData.class">
                  <span> <font-awesome-icon :icon="['fas', 'map-marker-alt']"/></span>
                  {{ tableData._id }} Plan
                </div>
              </template>
              <div class="main-table-section">
                <table class="table table-bordered">
                  <tbody class="lunch-menu-section">
                    <tr>
                      <td rowspan="3" colspan="2">
                        <div class="table-blanck-data"></div>
                      </td>
                      <td
                        colspan="2"
                        v-for="packageObj in tableData.packages"
                        v-bind:key="packageObj"
                      >
                        {{ beutifyTitles(packageObj.name) }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="2"
                        v-for="packageObj in tableData.packages"
                        v-bind:key="packageObj"
                      >
                        <h3>
                          Rs.
                          {{ packageObj.amount }}/-
                        </h3>
                      </td>
                    </tr>
                    <tr>
                      <td>veg</td>
                      <td>non-veg</td>
                      <td>veg</td>
                      <td>non-veg</td>
                      <td>veg</td>
                      <td>non-veg</td>
                    </tr>
                  </tbody>

                  <tbody class="menu-section">
                    <tr v-for="menuName in tableData.menu" v-bind:key="menuName">
                      <td colspan="2">
                        {{ menuName.name }}
                      </td>
                      <td v-for="amount in menuName.amount" v-bind:key="amount">
                        <div>
                          <b-form-group>
                            <b-form-input
                              v-model.number="amount.amount"
                              type="number"
                              size="sm"
                              required
                              @change="updateItemAmount(amount)"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </div> -->
      <div class="py-4">
        <!-- <pre>{{ menucontent }}</pre> -->
        <div class="font-14 pb-2 font-weight-bold">
          Breakfast (Breakfast Is Complimentary)
        </div>
        <b-row v-for="tableData in menuList" :key="tableData.key" in>
          <b-col id="menu-data" md="9">
            <div class="table-responsive">
              <table class="table-menu table-bordered table text-center">
                <thead class="table-content">
                  <tr class>
                    <th rowspan="2" class="menu-title">
                      {{ tableData._id }}
                    </th>
                    <th
                      v-for="packageObj in tableData.packages"
                      v-bind:key="packageObj"
                      colspan="2"
                      class
                    >
                      <div class="d-flex align-items-center">
                        <div class="mr-2 w-50">
                          {{ beutifyTitles(packageObj.name) }}
                        </div>
                        <div class="w-50">
                          <b-form-group>
                            <b-form-input
                              v-model.number="packageObj.amount"
                              size="sm"
                              class="text-center"
                              type="number"
                              required
                              @change="updatePackageAmount(packageObj, tableData.menu[0].category)"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th>Veg</th>
                    <th>Non-Veg</th>
                    <th>Veg</th>
                    <th>Non-Veg</th>
                    <th>Veg</th>
                    <th>Non-Veg</th>
                  </tr>
                </thead>
                <tbody class="table-data">
                  <tr v-for="menuName in tableData.menu" :key="menuName.key" class>
                    <td>
                      {{ menuName.name }}
                    </td>
                    <td v-for="amount in menuName.amount" v-bind:key="amount">
                      <div>
                        <b-form-group>
                          <b-form-input
                            v-model.number="amount.amount"
                            type="number"
                            size="sm"
                            required
                            @change="updateItemAmount(amount)"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
          <b-col id="note-data" md="3">
            <b-card class="note-content bg-light-grey">
              <div class="font-16 pink">
                Note:
              </div>
              <div class="font-14">
                <b-form-textarea
                  v-model="tableData.remark.note"
                  placeholder="For the Vegetarian Menu, everything that are mention as Non.Veg becomes Veg."
                  class="note-area"
                  type="text"
                  rows="3"
                  max-rows="6"
                  readonly
                  required
                ></b-form-textarea>
              </div>
            </b-card>
          </b-col>
          <div class="remark">
            <b-form-input
              v-model="tableData.remark.remark"
              type="text"
              placeholder=" Remarks"
              @change="updateRemark(tableData.menu[0].category, tableData.remark)"
            ></b-form-input>
          </div>
        </b-row>
        <div class="d-flex justify-content-center pt-5">
          <div>
            <b-button variant="success" @click="saveData()">Save</b-button>
          </div>
          <div class="ml-3">
            <b-button variant="danger">Cancel</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import service from "@/service/apiservice";
import global from "@/service/global";
import currency from "@/js/currency";

export default {
  data() {
    return {
      detail: {
        packedMeal: "",
        roomService: "",
        liquor: "",
        progressPage: "",
        progressCompleted: "",
        currency: ""
      },
      showButton: false,
      select_options: ["Yes", "No"],
      menuList: [],
      packageList: [],
      foodPreferences: [],
      packageAmount: [],
      itemAmount: [],
      options: [],
      remark: [],
      selected: "",
      currencies: currency
    };
  },
  validations: {
    detail: {
      currency: {
        required
      }
    }
  },
  created() {
    this.hotelId = global.getHotel();
    this.getOneHotel();
    service.getHotelMenuDetail(this.hotelId, menuResult => {
      this.menuList = menuResult.data.items;
      this.packageList = menuResult.data.package;
      this.foodPreferences = menuResult.data.foodPreference;
      this.packageAmount = menuResult.data.packageAmount;
      this.itemAmount = menuResult.data.itemAmount;
      this.remark = menuResult.data.remark;
      this.updatePackageList();
    });
  },
  methods: {
    getOneHotel() {
      service.getOneHotel(this.hotelId, data => {
        if (data.code === 200) {
          this.detail = data.data;
        
        }
      });
    },
    updatePackageList() {
      _.each(this.menuList, (menu, menuKey) => {
        menu.packages = [];
        if (menuKey == 0) {
          menu.class = "basic-grey";
        } else {
          if (menuKey % 2 == 0) {
            menu.class = "basic-pink";
          } else {
            menu.class = "basic-red";
          }
        }
        _.each(menu.menu, item => {
          item.amount = [];
          _.each(this.packageList, packageObject => {
            _.each(this.foodPreferences, preference => {
              let amount = null;
              const amountObj = {
                foodPackage: packageObject._id,
                category: item.category,
                item: item._id,
                foodPreference: preference._id
              };
              if (this.itemAmount.length > 0) {
                amount = _.find(this.itemAmount, amountObj);
              }
              if (amount) {
                amountObj.amount = amount.amount ? amount.amount : 0;
              } else {
                amountObj.amount = 0;
              }
              item.amount.push(amountObj);
            });
          });
        });
        _.each(this.packageList, packageObject => {
          // Package amount organisation
          let packageCopy = {
            name: packageObject.name,
            _id: packageObject._id
          };
          let amount = null;
          if (this.packageAmount.length > 0) {
            amount = _.find(this.packageAmount, {
              foodPackage: packageCopy._id,
              category: menu.menu[0].category
            });
            if (amount) {
              packageCopy.amount = amount.amount ? amount.amount : 0;
            }
          }
          menu.packages.push(packageCopy);
        });
        // Assign remark and note to category
        const remarkObj = _.find(this.remark, ["category", menu.menu[0].category]);
        if (remarkObj) {
          menu.remark = remarkObj;
        } else {
          menu.remark = { note: "", remark: "" };
        }
      });
    },
    updatePackageAmount(packageObj, item) {
   
      service.updatePackageAmount(
        {
          category: item,
          foodPackage: packageObj._id,
          amount: Number(packageObj.amount)
        },
        result => {
      
        }
      );
    },
    updateItemAmount(itemObj) {
      itemObj.amount = Number(itemObj.amount);
      service.updateItemAmount(itemObj, result => {
     
      });
    },
    saveData() {
      if (this.detail.progressCompleted == true) {
        this.detail.progressPage = "covid";
      } else {
        this.detail.progressPage = "menue";
      }
      this.$v.detail.$touch();
      if (this.$v.detail.$anyError) {
        this.$toaster.error("Currency is required");
        return;
      } else {
        service.updateHotelAdmin(this.detail, this.hotelId, saveResult => {
          this.$toaster.success("Details saved.");
        });
      }
    },
    updateRemark(obj, text) {

      service.updateRemark({ category: obj, note: text.note, remark: text.remark }, result => {});
    },
    beutifyTitles(text) {
      return _.capitalize(text);
    }
  }
};
</script>
<style lang="scss">
.hotel-details-menu {
  .card {
    margin: 20px 20px;
    .card-body {
      padding: 0px;
      border-radius: 30px;
      // .nav-link {
      //   padding: 0px;
      // }
      .nav-tabs .nav-link {
        // border: 1px solid black;
        padding: 10px 1rem;
        // border-top-left-radius: 0px;
        // border-top-right-radius: 0px;
      }
      .nav-item {
        border: 1px solid #ddd;
      }
      .nav-tabs .nav-link.active,
      .nav-tabs .nav-item.show .nav-link {
        //   color: $white;
        // background-color: none;
        // border: none;
        border-bottom: 1px solid #ddd;
      }
      a {
        //   color: $black;
        //   font-size: $font-16;
      }

      .main-table-section {
        .table {
          .lunch-menu-section {
            tr {
              td {
                width: 15%;
                //   font-size: $font-16;
                //   font-family: $roboto-regular;
                text-align: center;
                .table-blanck-data {
                  width: 10em;
                }
              }
            }
          }
          .menu-section {
            tr {
              td {
                text-align: center;
              }
            }
          }
        }
      }
      .basic-grey {
        //   color: $white;
        //   background: $basic-grey;
        padding: 25px;
        border-left: none;
        position: relative;
        ::after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          // border-color: $basic-grey transparent transparent transparent;
        }
      }
      .basic-pink {
        //   color: $white;
        //   background: $basic-pink;
        position: relative;
        padding: 25px;
        border-left: none;
        ::after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          // border-color: $basic-pink transparent transparent transparent;
        }
      }
      .basic-red {
        //   color: $white;
        //   background: $basic-lightpink;
        position: relative;
        border-left: none;
        padding: 25px;
        ::after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          // border-color: $basic-lightpink transparent transparent transparent;
        }
      }
    }
    .image-section {
      width: 40%;
      .menu-img {
        width: 100%;
        height: 100%;
      }
    }
    .table-menu {
      .table-data {
        .form-control {
          text-align: center;
        }
        tr {
          td {
            width: 10%;
          }
        }
      }
    }
    .remark {
      padding-right: 15px;
      padding-left: 15px;
      padding-bottom: 15px;
      width: 75%;
    }
  }
}
// .hotel-onfo-content{
//   .card{
//       margin: 0px;
//   }
// }
// @import "~/assets/scss/mobile";
</style>
