<template>
  <div class="venues-details">
    <div class="venues-details-section">
      <div>
        <div class="text-center">
          <div class="font-36">Venues</div>
        </div>
        <div>
          <b-form>
            <b-row v-for="(content, index) in detail.venues" :key="content.key">
              <b-col md="12" class="border-pink" v-if="index != 0">
                <div class="icon-section text-right py-2">
                  <font-awesome-icon
                    :icon="['fas', 'times']"
                    class="red font-16 pointer"
                    @click="removeData(index)"
                  />
                </div>
              </b-col>
              <b-col md="4">
                <!-- Name of Venues -->
                <div>
                  <b-form-group>
                    <label class="font-14">
                      Name of Venue*
                    </label>
                    <b-form-input
                      :state="validateState('name', index)"
                      aria-describedby="input-name-live-feedback"
                      id="venues"
                      v-model="content.name"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-name-live-feedback">
                      Venue Name is required.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </b-col>
              <b-col md="4">
                <!-- Room Type -->
                <div>
                  <b-form-group>
                    <label class="font-14">
                      Venue Type*
                    </label>
                    <b-form-select
                      :state="validateState('venueType', index)"
                      aria-describedby="input-venueType-live-feedback"
                      v-model="content.venueType"
                      :options="venueType"
                      value-field="_id"
                      text-field="name"
                    ></b-form-select>
                    <b-form-invalid-feedback id="input-venueType-live-feedback">
                      Venue Type is required.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </b-col>
              <b-col md="4">
                <!-- Venues Size -->
                <div class="venue-size">
                  <b-form-group>
                    <label class="font-14">
                      Venue Size
                    </label>

                    <div class="input-group">
                      <b-form-input type="number" id="Size" v-model="content.size"></b-form-input>

                      <div class="input-group-append">
                        <b-form-select v-model="content.type" :options="option"></b-form-select>
                      </div>
                    </div>
                  </b-form-group>
                </div>
              </b-col>
              <b-col md="4">
                <!-- Venues Size -->
                <div>
                  <b-form-group>
                    <label class="font-14">
                      Max Capacity upto*
                    </label>
                    <b-form-input
                      :state="validateState('maxCapacity', index)"
                      aria-describedby="input-maxCapacity-live-feedback"
                      id="Size"
                      type="number"
                      v-model="content.maxCapacity"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-maxCapacity-live-feedback">
                      Max Capacity is required.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </b-col>
              <!-- Venues Size -->

              <b-col md="4" class="pb-3">
                <label class="font-14">Layout</label>
                <b-form-file
                  ref="fileInput1"
                  type="file"
                  multiple
                  
                  @change="onFileChange($event.target.name, $event.target.files, index)"
                >
                </b-form-file>
              </b-col>
              <div class="spinner pt-4 pl-3 font-10" v-if="imageUploaded">
                <b-spinner label="Spinning"></b-spinner>
              </div>
              <b-col
                md="2"
                cols="4"
                v-for="(image, key) in detail.venues[index].attachment"
                class="my-2 mb-3"
                :key="image.index"
              >
                <div>
                  <div>
                    <div class="icon-section">
                      <font-awesome-icon
                        :icon="['fas', 'times']"
                        class="red font-12"
                        @click="remove(index, key)"
                      />
                    </div>
                    <img :src="image" class="img-fluid images ml-3" />
                  </div>
                </div>
              </b-col>
            </b-row>
            <div class="pt-3" v-if="detail.venues.length < 25">
              <b-button class="add-btn btn font-18" @click="add()">Add Venue</b-button>
            </div>
            <div v-if="showError" class="error-text pt-2 text-center">
              {{ message }}
            </div>
            <div class="d-flex justify-content-center pt-5">
              <div>
                <b-button variant="success" @click="updateHotel()">Save</b-button>
              </div>
              <div class="ml-3">
                <b-button variant="danger">Cancel</b-button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import service from "@/service/apiservice";
import global from "@/service/global";

export default {
  // props: ["detail"],
  data() {
    return {
      selectedFile: null,
      selected: null,
      option: [
        { value: "Sq mt", text: "Sq mt" },
        { value: "Sq ft", text: "Sq ft" }
      ],
      file: "",
      detail: {
        venues: [],
        progressCompleted: ""
      },
      venues: {
        name: "",
        venueType: "",
        file: null,
        size: "",
        attachment: [],
        maxCapacity: "",
        type: ""
        // inOutVenue: '',
      },
      venueType: [],
      options: [
        { value: "Out-Door", text: "Out-Door" },
        { value: "In-Door", text: "In-Door" }
      ],
      showError: false,
      message: "",
      showButton: false,
      imageUrl: "",
      PhotoName: "",
      imageFile: "",
      s3URL: "",
      details: [],
      imageView: [],
      pdfView: [],
      imageUploaded: false,
      hotelId: ""
    };
  },
  validations: {
    detail: {
      venues: {
        required,
        $each: {
          name: {
            required
          },
          venueType: {
            required
          },

          maxCapacity: {
            required
          }
        }
      }
    }
  },
  created() {
   
    this.detail.venues.push(this.venues);
    this.getOneHotel();
    service.getVenueType(venueTypeResult => {
      this.venueType = venueTypeResult.data;
    });
  },
  methods: {
    getOneHotel() {
      this.hotelId = global.getHotel();
      service.getOneHotel(this.hotelId, data => {
        if (data.code === 200) {
          this.detail = data.data;
      
        }
      });
    },
    add() {
      this.message = "";
      this.detail.venues.push({
        venues: "",
        type: "Sq mt",
        size: "",
        file: null,
        attachment: []
      });
    },
    validateState(name, index) {
      const { $dirty, $error } = this.$v.detail.venues.$each[index][name];
      return $dirty ? !$error : null;
    },

    onUpload() {
      const formData = new FormData();
      formData.append("myFile", this.selectedFile, this.selectedFile.name);
    },
    removeData(index) {
      this.detail.venues.splice(index, 1);
    },
    onImageData(index) {
      this.imageUploaded = true;
      const fd = new FormData();
      fd.append("file", this.imageFile, this.imageFile.name);
      service.awsPhotoUpload(fd, awsResponse => {
        if (awsResponse.status === 200) {
          this.imageUploaded = false;
          const predictionResults = awsResponse.data.file;
          this.s3URL = predictionResults;
          this.detail.venues[index].attachment.push(this.s3URL);
          // this.detail.venues[index].attachment = this.details
          this.$toaster.success("Image Save");
        } else {
          imageUploaded = false;
          this.$toaster.error("Image Not Saved");
        }
      });
    },
    onFileChange(fieldName, fileList, index) {
      const fileArray = [];
      for (let i = 0; i < fileList.length; i++) {
        fileArray.push(fileList[i]);
      }
      const newImages = fileArray.map((imageFile, index) => ({
        imageFile,
        imageURL: URL.createObjectURL(imageFile),
        name: imageFile.name,
        key: `${new Date().getTime()}-${index}`
      }));
      if (newImages.length >= 1) {
        for (let i = 0; i < newImages.length; i++) {
          this.imageUrl = newImages[i].imageURL;
          this.PhotoName = newImages[i].name;
          this.imageFile = newImages[i].imageFile;
          const imageSize = this.imageFile.size / 1024;
          this.onImageData(index);
        }
      }
    },
    remove(index, key) {
      this.detail.venues[index].attachment.splice(key, 1);
    },
    updateHotel() {
      this.$v.detail.venues.$touch();
      if (this.detail.venues.length > 0) {
        if (this.$v.detail.venues.$anyError) {
          this.$toaster.error("Please fill all mandatory fields.");
       
          return;
        }
     
        if (this.detail.venues.length <= 1 && !this.detail.venues[0].name) {
          this.showError = true;
          this.message = "Add atleast 1 Venue";
        } else {
          var save = {
            venues: []
          };
          _.map(this.detail.venues, function(e) {
            if (e.name) {
              save.venues.push(e);
            } else {
              delete e.venues;
            }
          });
          if (save.venues) {
            if (this.detail.progressCompleted == true) {
              save.progressPage = "covid";
            } else {
              save.progressPage = "venue";
            }

            return new Promise((resolve, reject) => {
           
              service.updateHotelAdmin(save, this.hotelId, saveResult => {
                if (saveResult) {
                 
                  this.$toaster.success("Details saved.");
                  this.showError = false;
                  resolve();
                } else {
                  reject();
                }
              });
            });
          }
        }
      } else {
        this.showError = true;
        this.$toaster.error("Add atleast 1 Venue");
        this.message = "Add atleast 1 Venue";
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/variables";
.venues-details {
  .venues-details-section {
    .custom-select {
      border-radius: 0px;
    }
    .form-control {
      border-radius: 0px;
    }
  }
  .image-section {
    width: 100%;
    height: 100%;
    .images {
      width: 100%;
      height: auto;
    }
  }
}
</style>
