<template>
  <div class="covid-section">
    <div class="covid pb-5">
      <div>
        <h4>Covid - 19 Measures</h4>
      </div>
      <div>
        <div class="covid-form-section">
          <b-row v-for="(item, index) in detail.covid" :key="item.key">
            <b-col md="8">
              <label>{{ item.question }}</label>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-form-select
                  :options="item.options"
                  v-model="item.answer"
                  :state="validateState('answer', index)"
                  aria-describedby="input-starRating-live-feedback"
                ></b-form-select>
                <b-form-invalid-feedback id="input-starRating-live-feedback">
                  Option is required
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div>
        <b-button variant="success" @click="saveData()">Save</b-button>
      </div>
      <div class="ml-3">
        <b-button variant="danger">Cancel</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import service from "@/service/apiservice";
import global from "@/service/global";
export default {
  layout: "dashboard",

  // props: ['detail'],
  data() {
    return {
      showButton: false,
      detail: {
        hotelId: "",
        progressCompleted: "",
        progressPage: "",

        covid: [
          {
            question: "We provide Covid kit at the time of arrival",
            options: [
              { value: "Yes", text: "Yes" },
              { value: "No", text: "No" }
            ],
            answer: ""
          },
          {
            question: "We have a sanitising tunnel at the hotel entry",
            options: [
              { value: "Yes", text: "Yes" },
              { value: "No", text: "No" }
            ],
            answer: ""
          },
          {
            question: "We do temperature checks at arrival",
            options: [
              { value: "Yes", text: "Yes" },
              { value: "No", text: "No" }
            ],
            answer: ""
          },
          {
            question: "We disinfect guest luggages on arrivals",
            options: [
              { value: "Yes", text: "Yes" },
              { value: "No", text: "No" }
            ],
            answer: ""
          },
          {
            question:
              "We collect guests travel data at the time of reservation to ensure knowledge of restricted country / region travel ",
            options: [
              { value: "Yes", text: "Yes" },
              { value: "No", text: "No" }
            ],
            answer: ""
          },
          {
            question:
              "We ensure reduced contact by completing formalities online for pre booked Guests",
            options: [
              { value: "Yes", text: "Yes" },
              { value: "No", text: "No" }
            ],
            answer: ""
          },
          {
            question: "We share safety & hygiene guidelines upon reservation & arrival ",
            options: [
              { value: "Yes", text: "Yes" },
              { value: "No", text: "No" }
            ],
            answer: ""
          },
          {
            question: "We have social distancing marking at key areas where necessary ",
            options: [
              { value: "Yes", text: "Yes" },
              { value: "No", text: "No" }
            ],
            answer: ""
          },
          {
            question: "We provide kit in our rooms (Face mask, Sanitiser and Hand gloves) ",
            options: [
              { value: "Yes", text: "Yes" },
              { value: "No", text: "No" }
            ],
            answer: ""
          },

          {
            question: "We sanitize our linens everyday ",
            options: [
              { value: "Yes", text: "Yes" },
              { value: "No", text: "No" }
            ],
            answer: ""
          },

          {
            question: "We sanitize all areas & surfaces of touch points (within buidling areas) ",
            options: [
              { value: "Twice a day", text: "Twice a day" },
              { value: "Thrice a day", text: "Thrice a day" },
              { value: "Every 2 hrs", text: "Every 2 hrs" },
              { value: "Every 3 hrs", text: "Every 3 hrs" },
              { value: "Every 4 hrs", text: "Every 4 hrs" },
              { value: "Every 5 hrs", text: "Every 5 hrs" }
            ],
            answer: ""
          },
          {
            question: "We sanitise our rooms during turn down service ",
            options: [
              { value: "Yes", text: "Yes" },
              { value: "No", text: "No" }
            ],
            answer: ""
          },
          {
            question: "We provide Covid Kit in Cars (Face mask, Sanitiser and HandGloves)",
            options: [
              { value: "Yes", text: "Yes" },
              { value: "No", text: "No" }
            ],
            answer: ""
          },
          {
            question: "We disinfect the cars after every arrival ",
            options: [
              { value: "Yes", text: "Yes" },
              { value: "No", text: "No" }
            ],
            answer: ""
          },
          {
            question: "Our Breakfast is ",
            options: [
              { value: "Buffet", text: "Buffet" },
              { value: "Table Served", text: "Table Served" }
            ],
            answer: ""
          },
          {
            question: "For Lunch / Dinner at restaurants are",
            options: [
              { value: "Buffet", text: "Buffet" },
              { value: "Ala Carte", text: "Ala Carte" }
            ],
            answer: ""
          },

          {
            question: "We ensure our associates are wearing masks & gloves",
            options: [
              { value: "Yes", text: "Yes" },
              { value: "No", text: "No" }
            ],
            answer: ""
          },
          {
            question: "Sanitisers are placed at Key areas of the property",
            options: [
              { value: "Yes", text: "Yes" },
              { value: "No", text: "No" }
            ],
            answer: ""
          },
          {
            question: "New SOP's are introduced for laundry & housekeeping",
            options: [
              { value: "Yes", text: "Yes" },
              { value: "No", text: "No" }
            ],
            answer: ""
          },
          {
            question: "We change room linens",
            options: [
              { value: "Twice everyday", text: "Twice everyday" },
              { value: "Once everyday", text: "Once everyday" },
              {
                value: "Once in two day",
                text: "Once in two day"
              },
              {
                value: "Once in three day",
                text: "Once in three day"
              },
              { value: "Other", text: "Other" }
            ],
            answer: ""
          },
          {
            question: "We have reduced our banquet capacity by",
            options: [
              { value: "25%", text: "25%" },
              { value: "30%", text: "30%" },
              { value: "40%", text: "40%" },
              { value: "50%", text: "50%" }
            ],
            answer: ""
          },
          {
            question:
              "We ensure all working vendor staff including valet, decorator, event manager etc undergo temperature check and wear face mask & gloves at all times",
            options: [
              { value: "Yes", text: "Yes" },
              { value: "No", text: "No" },
              { value: "Yet to be Done", text: "Yet to be Done" }
            ],
            answer: ""
          },
          {
            question: "We only allow empanelled vendors in hotel",
            options: [
              { value: "Yes", text: "Yes" },
              { value: "No", text: "No" },
              { value: "Conditional", text: "Conditional" }
            ],
            answer: ""
          },
          {
            question: "Pool, Gym & Spa",
            options: [
              {
                value: "Open with Social Distancing parameters",
                text: "Open with Social Distancing parameters"
              },
              {
                value: "Non Operation until further notice",
                text: "Non Operation until further notice"
              },

              ,
            ],
            answer: ""
          },
          {
            question: "Business centers & Meeting rooms",
            options: [
              {
                value: "Open with Social Distancing parameters",
                text: "Open with Social Distancing parameters"
              },
              {
                value: "Non Operation until further notice",
                text: "Non Operation until further notice"
              }
            ],
            answer: ""
          }
        ]
      }
    };
  },
  validations: {
    detail: {
      covid: {
        required,
        $each: {
          answer: {
            required
          }
        }
      }
    }
  },
  created() {
    this.getOneHotel();
  },
  methods: {
    validateState(name, index) {
      const { $dirty, $error } = this.$v.detail.covid.$each[index][name];

      return $dirty ? !$error : null;
    },
    getOneHotel() {
      this.hotelId = global.getHotel();
      service.getOneHotel(this.hotelId, data => {
        if (data.code === 200) {
          this.detail.progressCompleted = data.data.progressCompleted;
          this.detail.progressPage = data.data.progressPage;
          if (data.data.covid.length == 0) {
            this.detail = this.detail;
          } else if (data.data.covid) {
            this.detail.covid = data.data.covid;
          }
        }
      });
    },

    saveData() {
      this.$v.detail.$touch();
      if (this.$v.detail.$anyError) {
        return;
      }
      return new Promise((resolve, reject) => {
        this.detail.progressPage = "covid";
        this.detail.progressCompleted = true;

        service.updateHotelAdmin(this.detail, this.hotelId, result => {
          if (result) {
            this.$toaster.success("Details saved.");
            resolve();
          } else {
            reject();
          }
        });
      });
    }
  }
};
</script>
