<template>
  <div class="contact-section">
    <div class="text-center font-32">
      <div>Contact Us</div>
    </div>
    <div class="contact-detail-section">
      <div class="pt-3">
        <div class="info">
          <!-- <div>(For Company Info Only)</div> -->
          <div>
            (Information shared is strictly confidential and subjected to limited visibility between
            Hotel and WedDestination Management)
          </div>
        </div>
        <!-- <div class="font-16">For Company Info Only :</div> -->
        <b-row v-for="(content, index) in companyUse" :key="content.key" class="pt-2 mx-2">
          <b-row class="pt-1">
            <b-col md="6">
              <label class="font-14">Name</label>
              <b-form-input id="name" placeholder="Name" v-model="content.name"></b-form-input>
            </b-col>
            <b-col md="6">
              <label class="font-14">Designation</label>
              <b-form-input
                id="title"
                v-model="content.title"
                :disabled="
                  content.title == 'General Manager' || content.title == 'Director of Sales'
                "
                placeholder="Designation"
              ></b-form-input>
            </b-col>

            <b-col md="6" class="form-group pt-3">
              <label class="font-14">Contact</label>
              <b-form-input
                id="contact"
                type="number"
                onkeypress="if(this.value.length==15) return false"
                v-model="content.contact"
                placeholder="Number"
              ></b-form-input>

              <div
                class="error-text py-1"
                v-if="!$v.companyUse.$each[index].contact.minLength"
              ></div>
            </b-col>
            <b-col md="6" class="pt-3">
              <label class="font-14">Email</label>
              <b-form-input id="email" v-model="content.email" placeholder="Email"></b-form-input>

              <div v-if="!$v.companyUse.$each[index].email.email" class="error-text py-1">
                Email is not a properly formatted email address.
              </div>
            </b-col>
          </b-row>
        </b-row>
      </div>
      <div class="pt-1">
        <!-- <div class="font-16">For Other Contact Detail :</div> -->
        <b-row v-for="(content, index) in otherUse" :key="content.key" class="pt-2 mx-2">
          <b-col md="12">
            <div class="mt-3 text-right" v-if="index > 2">
              <font-awesome-icon
                :icon="['fas', 'times']"
                class="red font-16 pointer"
                @click="removeData(index)"
              />
            </div>
          </b-col>
          <b-row class="pt-2">
            <b-col md="6">
              <label class="font-14">Name</label>
              <b-form-input id="name1" placeholder="Name" v-model="content.name"></b-form-input>
            </b-col>
            <b-col md="6">
              <label class="font-14">Designation</label>
              <b-form-input
                id="title1"
                v-model="content.title"
                :disabled="
                  content.title == 'Sales Manager' ||
                    content.title == 'Head of Events' ||
                    content.title == 'Accounts Manager'
                "
                placeholder="Designation"
              ></b-form-input>
            </b-col>
            <b-col md="6">
              <label class="font-14 pt-2">Contact</label>
              <b-form-input
                type="number"
                id="contact"
                v-model="content.contact"
                placeholder="Number"
                onkeypress="if(this.value.length==15) return false"
              ></b-form-input>

              <div class="error-text py-1" v-if="!$v.otherUse.$each[index].contact.minLength">
                Contact Number must have at least 10 Digit.
              </div>
            </b-col>
            <b-col md="6">
              <label class="font-14 pt-2">Email</label>
              <b-form-input id="contact" v-model="content.email" placeholder="Email"></b-form-input>

              <div v-if="!$v.otherUse.$each[index].email.email" class="error-text py-1">
                Email is not a properly formatted email address.
              </div>
            </b-col>
          </b-row>
        </b-row>
      </div>

      <div class="pt-3 pl-2">
        <b-button class="add-btn font-14" @click="add()">Add Contact</b-button>
      </div>
      <div class="d-flex justify-content-center pt-5">
        <div>
          <b-button variant="success" @click="saveData()">Save</b-button>
        </div>
        <div class="ml-3">
          <b-button variant="danger">Cancel</b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
import service from "@/service/apiservice";
import global from "@/service/global";

export default {
  // props: ["detail"],
  data() {
    return {
      detail: {
        contactNumber: [],
        progressPage: "",
        progressCompleted: ""
      },
      hotelId: "",
      companyUse: [
        {
          title: "General Manager",
          name: "",
          contact: "",
          email: ""
        },
        {
          title: "Director of Sales",
          name: "",
          contact: "",
          email: ""
        }
      ],
      otherUse: [
        {
          title: "Sales Manager",
          name: "",
          contact: "",
          email: ""
        },
        {
          title: "Head of Events",
          name: "",
          contact: "",
          email: ""
        },
        {
          title: "Accounts Manager",
          name: "",
          contact: "",
          email: ""
        }
      ]
    };
  },
  validations: {
    companyUse: {
      required,
      $each: {
        email: {
          email
        },
        contact: {
          minLength: minLength(10)
        }
      }
    },
    otherUse: {
      required,
      $each: {
        email: {
          email
        },
        contact: {
          minLength: minLength(10)
        }
      }
    }
  },
  created() {
    this.getOneHotel();
  },
  methods: {
    getOneHotel() {
      this.hotelId = global.getHotel();
      service.getOneHotel(this.hotelId, data => {
        if (data.code === 200) {
          this.detail = data.data;
          const array = data.data.contactNumber;
          this.detail.progressPage = data.data.progressPage;
          if (array.length == 0) {
            this.companyUse = this.companyUse;
            this.otherUse = this.otherUse;
          } else {
            this.companyUse = [];
            this.otherUse = [];
            _.each(array, n => {
              if (
                _.isEqual(n.title, "General Manager") ||
                _.isEqual(n.title, "Director of Sales")
              ) {
                this.companyUse.push(n);
              } else {
                this.otherUse.push(n);
              }
            });
          }
        }
      });
    },
    saveData() {
      this.$v.companyUse.$touch();
      this.$v.otherUse.$touch();

      this.detail.contactNumber = this.companyUse.concat(this.otherUse);

      var save = {
        contactNumber: []
      };
      _.map(this.detail.contactNumber, function(e) {
        if (e.name) {
          save.contactNumber.push(e);
        } else {
          delete e.contactNumber;
        }
      });
      if (save.contactNumber) {
        if (this.detail.progressCompleted == true) {
          save.progressPage = "covid";
        } else {
          save.progressPage = "contact";
        }
        service.updateHotelAdmin(save, this.hotelId, saveResult => {
          if (saveResult) {
            this.$toaster.success("Details saved.");
            this.showButton = true;
          }
        });
      }
    },
    add() {
      this.otherUse.push({ title: "", name: "", contact: "", email: "" });
    },
    removeData(index) {
      this.otherUse.splice(index, 1);
    }
  }
};
</script>
