<template>
  <div class="room-amenities">
    <div class="room-details">
      <div class="room-details-section">
        <div>
          <div class="text-center">
            <div class="font-36">Room Details</div>
          </div>
          <div>
            <b-form>
              <div>
                <b-row>
                  <b-col class="col-md-4">
                    <div class="font-26 py-3">
                      Total No. of Rooms.
                    </div></b-col
                  >

                  <b-col class="pt-2 col-md-3">
                    <div>
                      <b-form-input
                        :state="validateState('NoOfRooms')"
                        v-model="details.NoOfRooms"
                        id="input-2"
                        readonly
                        aria-describedby="input-NoOfRooms-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-NoOfRooms-feedback">
                        NoOfRooms is required
                      </b-form-invalid-feedback>
                    </div>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group>
                      <label class="font-14">
                        Accommodation Types (maximum 4)*
                      </label>
                      <multiselect
                        v-model="details.rooms.accommodationTypes"
                        track-by="name"
                        multiple
                        :max="4"
                        label="name"
                        limitSelection="4"
                        :options="accommodationTypeOptions"
                        :searchable="true"
                        :allow-empty="false"
                      ></multiselect>
                      <b-form-invalid-feedback id="input-propertyType-live-feedback">
                        Select your accommodation type
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row
                  v-for="(content, index) in $v.details.rooms.roomDetails.$each.$iter"
                  :key="content.key"
                >
                  <b-col md="12" v-if="index != 0">
                    <div class="icon-section text-right">
                      <font-awesome-icon
                        :icon="['fas', 'times']"
                        class="red font-16 pointer"
                        @click="removeData(index)"
                      />
                    </div>
                  </b-col>
                  <b-col md="12 border-bottom-pink">
                    <b-row>
                      <b-col md="6">
                        <div>
                          <b-form-group>
                            <label class="font-14">
                              Rooms Category*
                            </label>
                            <b-form-select
                              id="input-2"
                              :state="validateStateRoomDetail('roomType', index)"
                              :options="roomTypeOptions"
                              v-model="content.roomType.$model"
                              attr.aria-describedby="index"
                            ></b-form-select>

                            <b-form-invalid-feedback :id="index">
                              room type is required
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                      </b-col>
                      <b-col md="6">
                        <div>
                          <b-form-group>
                            <label class="font-14">
                              Room Size*
                            </label>
                            <b-input-group append="sq mt">
                              <b-form-input
                                type="number"
                                :state="validateStateRoomDetail('size', index)"
                                attr.aria-describedby="index"
                                v-model="content.size.$model"
                              ></b-form-input>
                              <b-form-invalid-feedback :id="index">
                                Size is required
                              </b-form-invalid-feedback>
                            </b-input-group>
                          </b-form-group>
                        </div>
                      </b-col>
                      <b-col md="6">
                        <div>
                          <b-form-group>
                            <label class="font-14">
                              Type Of Bed*
                            </label>
                            <b-form-select
                              :state="validateStateRoomDetail('typeOfBed', index)"
                              attr.aria-describedby="index"
                              :options="options"
                              v-model="content.typeOfBed.$model"
                            ></b-form-select>
                            <b-form-invalid-feedback :id="index">
                              typeOfBed is required
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                      </b-col>
                      <b-col md="6">
                        <div>
                          <b-form-group>
                            <label class="font-14">Quantity*</label>
                            <b-form-input
                              :state="validateStateRoomDetail('quantity', index)"
                              attr.aria-describedby="index"
                              v-model="content.quantity.$model"
                              type="number"
                            ></b-form-input>
                            <b-form-invalid-feedback :id="index">
                              Quantity is required
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <div class>
                  <b-button class="add-btn btn font-14" @click="add()">Add Room</b-button>
                </div>
                <b-row>
                  <!-- Address -->
                  <b-col md="6">
                    <div>
                      <b-form-group>
                        <label class="font-14">
                          Total No. of Extra Bed*
                        </label>
                        <b-form-input
                          type="number"
                          :state="validateStateRooms('noOfExtraBed')"
                          v-model="$v.details.rooms.noOfExtraBed.$model"
                          id="input-2"
                          aria-describedby="input-noOfExtraBed-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-noOfExtraBed-feedback">
                          No Of Extra Bed is required
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="6">
                    <div>
                      <b-form-group>
                        <label class="font-14">
                          Total No. of Handicap Room*
                        </label>
                        <b-form-input
                          type="number"
                          :state="validateStateRooms('noOfHandicapRoom')"
                          v-model="$v.details.rooms.noOfHandicapRoom.$model"
                          id="input-2"
                          aria-describedby="input-noOfHandicapRoom-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-noOfHandicapRoom-feedback">
                          No Of Handicap Room is required
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
    <div class="amenities-details">
      <div class="amenities-details-section">
        <div>
          <div class="text-center">
            <div class="font-36">Amenities</div>
          </div>
          <div>
            <b-form>
              <!-- Amenities -->
              <div>
                <div class="font-18 py-3">
                  Room Amenities
                  <span class="font-12">
                    (Select minimum 7)
                  </span>
                </div>

                <b-row>
                  <b-col v-for="amenitie in roomAmenities" :key="amenitie.key" md="4">
                    <div class="checkbox-section">
                      <b-form-group>
                        <b-form-checkbox-group
                          id="checkbox-group-2"
                          v-model="details.amenities.roomAmenities"
                          name="flavour-2"
                        >
                          <b-form-checkbox
                            class="font-14"
                            :value="{
                              name: amenitie.name,
                              roomAmenity: amenitie._id
                            }"
                          >
                            {{ amenitie.name }}
                          </b-form-checkbox>
                        </b-form-checkbox-group>
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <!-- Amenities 1 -->
              <div>
                <div class="font-18 py-3">
                  Bathroom Amenities
                  <span class="font-12">
                    (Select minimum 5)
                  </span>
                </div>
                <b-row>
                  <b-col v-for="premise in bathroomAmenities" :key="premise.key" md="4">
                    <div class="checkbox-section">
                      <b-form-group>
                        <b-form-checkbox-group
                          id="checkbox-group-2"
                          v-model="details.amenities.bathroomAmenities"
                          name="flavour-2"
                        >
                          <b-form-checkbox
                            class="font-14"
                            :value="{
                              name: premise.name,
                              bathroomAmenity: premise._id
                            }"
                          >
                            {{ premise.name }}
                          </b-form-checkbox>
                        </b-form-checkbox-group>
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div v-if="showError" class="error-text pt-2 text-center">
                {{ message }}
              </div>
              <div class="d-flex justify-content-center">
                <div>
                  <b-button variant="success" @click="updateHotel()">Save</b-button>
                </div>
                <div class="ml-3">
                  <b-button variant="danger">Cancel</b-button>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import service from "@/service/apiservice";
import global from "@/service/global";
export default {
  components: {
    Multiselect
  },
  // props: ["detail"],

  data() {
    return {
      value: null,
      totalqtycount: 0,
      details: {
        amenities: {
          roomAmenities: [],
          bathroomAmenities: []
        },
        rooms: {
          roomDetails: [
            {
              roomType: null,
              size: null,
              typeOfBed: null,
              quantity: null
            }
          ],
          noOfExtraBed: null,
          noOfHandicapRoom: null,
          accommodationTypes: []
        },
        NoOfRooms: null,
        progressPage: "",
        progressCompleted: ""
      },

      contents: [
        {
          key: 1,
          venues: "",
          type: "",
          Size: "",
          typeOfBed: ""
        }
      ],
      roomAmenities: [],
      bathroomAmenities: [],
      options: [
        { value: "King Bed", text: "King Bed" },
        { value: "Queen Bed", text: "Queen Bed" },
        { value: "Twin Bed", text: "Twin Bed" }
      ],
      roomTypeOptions: [
        { value: "Deluxe Room", text: "Deluxe Room" },
        { value: "Standard Room", text: "Standard Room" },
        { value: "Superior Room", text: "Superior Room" },
        { value: "Luxury Tent", text: "Luxury Tent" },
        { value: "Cottage", text: "Cottage" },
        { value: "Suite", text: "Suite" },
        { value: "Villa", text: "Villa" },
        { value: "Villa with Courtyard", text: "Villa with Courtyard" },
        {
          value: "Villa with Private Pool",
          text: "Villa with Private Pool"
        },
        { value: "Presidential Suite", text: "Presidential Suite" }
      ],
      accommodationTypeOptions: [
        { value: "Rooms", name: "Rooms" },
        { value: "Tents", name: "Tents" },
        { value: "Cottages", name: "Cottages" },
        { value: "Suites", name: "Suites" },
        { value: "Villas", name: "Villas" },
        { value: "Presidential Suite", name: "Presidential Suite" }
      ],
      showError: false,
      message: "",
      hotelId: ""
    };
  },
  validations: {
    details: {
      NoOfRooms: { required },
      rooms: {
        noOfExtraBed: { required },
        noOfHandicapRoom: { required },
        accommodationTypes: { required },
        roomDetails: {
          $each: {
            roomType: {
              required
            },
            size: {
              required
            },
            typeOfBed: {
              required
            },
            quantity: {
              required
            }
          }
        }
      }
    }
  },
  created() {
    this.getOneHotel();
  },
  methods: {
    getOneHotel() {
      this.hotelId = global.getHotel();
      service.getOneHotel(this.hotelId, data => {
        if (data.code === 200) {
          this.details = data.data;

          if (this.details.rooms.roomDetails.length == 0) {
            this.details.rooms.roomDetails.push({
              roomType: "",
              size: "",
              quantity: "",
              typeOfBed: null
            });
          }
          _.each(this.details.amenities.roomAmenities, function(n) {
            delete n._id;
          });
          _.each(this.details.amenities.bathroomAmenities, function(n) {
            delete n._id;
          });
          service.getRoomAmenities(roomAmenitiesResult => {
            this.roomAmenities = roomAmenitiesResult.data;
          });
          service.getBathRoomAmenities(bathRoomAmenitiesResult => {
            this.bathroomAmenities = bathRoomAmenitiesResult.data;
          });
        }
      });
    },
    validateStateRooms(name) {
      const { $dirty, $error } = this.$v.details.rooms[name];
      return $dirty ? !$error : null;
    },
    validateStateRoomDetail(name, index) {
      const { $dirty, $error } = this.$v.details.rooms.roomDetails.$each[index][name];
      return $dirty ? !$error : null;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.details[name];
      return $dirty ? !$error : null;
    },
    removeData(index) {
      this.details.rooms.roomDetails.splice(index, 1);
    },
    updateHotel() {
      this.$v.details.$touch();

      this.totalqtycount = parseInt(
        this.details.rooms.roomDetails
          .map(item => Number(item.quantity))
          .reduce((prev, curr) => prev + curr)
      );

      if (this.$v.details.$anyError) {
        this.$toaster.error("Please fill all mandatory fields.");
        return;
      } else {
        if (this.details.amenities.roomAmenities.length < 7) {
          this.showError = true;
          this.message = "Room Amenities: Min 7 is Mandatory";
        } else if (this.details.amenities.bathroomAmenities.length < 5) {
          this.showError = true;
          this.message = "Bath Room Amenities: Min 5 is Mandatory";
        } else if (this.totalqtycount > this.details.NoOfRooms) {
          this.$toaster.error("Total quantity cannot be more than no. of Rooms");
          this.showError = true;
          this.message = "Total quantity cannot be more than no. of Rooms";
        } else {
          return new Promise((resolve, reject) => {
            service.updateHotelAdmin(this.details, this.hotelId, saveResult => {
              if (saveResult) {
                this.$toaster.success("Details saved.");
                this.showError = false;

                resolve();
              } else {
                reject();
              }
            });
          });
        }
      }
    },
    add() {
      this.details.rooms.roomDetails.push({
        roomType: "",
        size: "",
        quantity: "",
        typeOfBed: ""
      });
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/variables";
.room-amenities {
  .room-details {
    .room-details-section {
      .custom-select {
        border-radius: 0px;
      }
      .form-control {
        border-radius: 0px;
      }
    }
  }
}
</style>
